'use client';

import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/navigation';

interface SearchBarProps {
    placeholder: string;
    buttonText: string;
    searchUrl: string;
    className?: string;
    background?: string;
    titleColor?: string;
}

const SearchBar: React.FC<SearchBarProps> = ({
    placeholder,
    buttonText,
    searchUrl,
    className = '',
    background = "bg-secondary",
    titleColor = "text-black",
}) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPlaceholder, setCurrentPlaceholder] = useState('');
    const router = useRouter();

    // Array of example cancer conditions
    const placeholders = [
        "Breast Cancer",
        "Basal Cell Skin Cancer",
        "Melanoma",
        "Leukemia",
        "Lymphoma",
        "Ovarian Cancer",
        "Pancreatic Cancer"
    ];

    useEffect(() => {
        let currentIndex = 0;
        let currentChar = 0;
        let isDeleting = false;
        let timeoutId: NodeJS.Timeout;

        const animatePlaceholder = () => {
            const currentText = placeholders[currentIndex];
            
            if (isDeleting) {
                setCurrentPlaceholder(currentText.substring(0, currentChar));
                currentChar--;
                
                if (currentChar === 0) {
                    isDeleting = false;
                    currentIndex = (currentIndex + 1) % placeholders.length;
                }
            } else {
                setCurrentPlaceholder(currentText.substring(0, currentChar));
                currentChar++;
                
                if (currentChar > currentText.length) {
                    isDeleting = true;
                    // Pause at the end of the word
                    timeoutId = setTimeout(animatePlaceholder, 3000);
                    return;
                }
            }

            // Typing speed
            const speed = isDeleting ? 50 : 100;
            timeoutId = setTimeout(animatePlaceholder, speed);
        };

        animatePlaceholder();

        return () => {
            if (timeoutId) clearTimeout(timeoutId);
        };
    }, []);

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        const encodedQuery = encodeURIComponent(searchQuery);
        const fullUrl = `${searchUrl}#query=${encodedQuery}`;
        router.push(fullUrl);
    };

    return (
        <div className={`rh-searchbar px-2 py-4 border-y-1 border-primary ${background} ${className}`}>
            <form onSubmit={handleSubmit} className="relative w-full mx-auto">
                <div className="container mx-auto relative">
                    <label
                        htmlFor="searchbar-input"
                        className="sr-only"
                    >
                        search query
                    </label>
                    
                    <input
                        id="searchbar-input"
                        type="text"
                        placeholder={currentPlaceholder}
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className="rh-searchbar-input"
                    />
                    <button
                        type="submit"
                        className="rh-searchbar-btn"
                    >
                        {buttonText}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default SearchBar;