import log from "loglevel";

// Set default level to INFO
const defaultLevel = log.levels.INFO;

// Get log level from environment variable
const envLogLevel = process.env.LOG_LEVEL?.toLowerCase();

// Use environment variable if valid, otherwise use default
const validLevels = [
  "trace",
  "debug",
  "info",
  "warn",
  "error",
  "silent",
] as const;
type LogLevel = (typeof validLevels)[number];

const logLevel =
  envLogLevel && validLevels.includes(envLogLevel as LogLevel)
    ? (envLogLevel as log.LogLevelDesc)
    : defaultLevel;

log.setLevel(logLevel);

const logger = {
  info: (message: string, ...args: any[]) => log.info(message, ...args),
  warn: (message: string, ...args: any[]) => log.warn(message, ...args),
  error: (message: string, ...args: any[]) => log.error(message, ...args),
  debug: (message: string, ...args: any[]) => log.debug(message, ...args),
  setLevel: (level: log.LogLevelDesc) => log.setLevel(level),
};

export default logger;
