'use client';

import React from 'react';
import { motion, useInView } from 'framer-motion';
import { useRef } from 'react';

interface AnimatedTextProps {
    children: React.ReactNode;
    className?: string;
    delay?: number;
}

const AnimatedText: React.FC<AnimatedTextProps> = ({ 
    children, 
    className = "",
    delay = 0
}) => {
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true, margin: "-50px" });

    return (
        <motion.div
            ref={ref}
            className={className}
            variants={{
                hidden: { opacity: 0, y: 20 },
                visible: { opacity: 1, y: 0 }
            }}
            initial="hidden"
            animate={isInView ? "visible" : "hidden"}
            transition={{ 
                duration: 0.4, 
                delay,
                ease: [0.21, 0.47, 0.32, 0.98]
            }}
        >
            {children}
        </motion.div>
    );
};

interface AnimatedHTMLProps {
    html: string;
    className?: string;
    delay?: number;
}

export const AnimatedHTML: React.FC<AnimatedHTMLProps> = ({
    html,
    className = "",
    delay = 0
}) => {
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true, margin: "-50px" });

    return (
        <motion.div
            ref={ref}
            className={className}
            variants={{
                hidden: { opacity: 0, y: 20 },
                visible: { opacity: 1, y: 0 }
            }}
            initial="hidden"
            animate={isInView ? "visible" : "hidden"}
            transition={{ 
                duration: 0.4, 
                delay,
                ease: [0.21, 0.47, 0.32, 0.98]
            }}
            dangerouslySetInnerHTML={{ __html: html }}
        />
    );
};

export default AnimatedText; 