'use client';

import React, { useState, useEffect, useRef } from 'react';
import { useIntercom } from '@/layers/presentation/context/IntercomContext';
import { Button } from '@nextui-org/react';
import logger from '@/layers/support/monitoring/logger';

const LOG_MODULE = '[Presentation / Components / FeedbackButton]';

const FeedbackButton: React.FC = () => {
  const { triggerFeedback } = useIntercom();
  const [isSelecting, setIsSelecting] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const selectedElementRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    if (isSelecting) {
      document.body.style.cursor = 'crosshair';
      document.addEventListener('click', handleElementClick, true);
      document.addEventListener('keydown', handleKeyDown);
    } else {
      document.body.style.cursor = 'default';
      document.removeEventListener('click', handleElementClick, true);
      document.removeEventListener('keydown', handleKeyDown);
      if (selectedElementRef.current) {
        selectedElementRef.current.style.outline = '';
      }
    }

    return () => {
      document.body.style.cursor = 'default';
      document.removeEventListener('click', handleElementClick, true);
      document.removeEventListener('keydown', handleKeyDown);
      if (selectedElementRef.current) {
        selectedElementRef.current.style.outline = '';
      }
    };
  }, [isSelecting]);

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      setIsSelecting(false);
      setIsConfirmed(false);
      if (selectedElementRef.current) {
        selectedElementRef.current.style.outline = '';
        selectedElementRef.current = null;
      }
    }
  };

  const handleElementClick = async (e: MouseEvent) => {
    if (buttonRef.current && buttonRef.current.contains(e.target as Node)) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    if (selectedElementRef.current) {
      selectedElementRef.current.style.outline = '';
    }

    const element = e.target as HTMLElement;
    element.style.outline = '2px solid red';

    selectedElementRef.current = element;
    setIsConfirmed(true);
  };

  const handleButtonClick = () => {
    if (!isSelecting && !isConfirmed) {
      setIsSelecting(true);
      if (selectedElementRef.current) {
        selectedElementRef.current.style.outline = '';
        selectedElementRef.current = null;
      }
      setIsConfirmed(false);
    } else if (isConfirmed) {
      if (selectedElementRef.current) {
        const elementInfo = {
          tag: selectedElementRef.current.tagName,
          id: selectedElementRef.current.id,
          classes: selectedElementRef.current.className,
          text: selectedElementRef.current.innerText,
        };

        triggerFeedback({
          feedback_type: 'user_feedback',
          elementInfo,
          page_url: window.location.href,
          timestamp: new Date().toISOString(),
        });

        setIsSelecting(false);
        setIsConfirmed(false);
        if (selectedElementRef.current) {
          selectedElementRef.current.style.outline = '';
          selectedElementRef.current = null;
        }
      } else {
        logger.error(`${LOG_MODULE} No element selected to confirm.`);
      }
    }
  };

  return (
    <Button
      ref={buttonRef}
      onPress={handleButtonClick}
      className={`fixed left-2 bottom-0 z-50 feedback-button rounded-b-none`}
      color={isConfirmed ? 'success' : isSelecting ? 'warning' : 'primary'}
      title={
        isConfirmed
          ? 'Confirm your feedback'
          : isSelecting
            ? 'Select an element to report'
            : 'Report an Issue'
      }
    >
      {isConfirmed
        ? 'Confirm or Select Other'
        : isSelecting
            ? 'Select Element to Report'
            : 'Report an Issue'}
    </Button>
  );
};

export default FeedbackButton;