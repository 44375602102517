'use client';

import React, { useState } from 'react';
import { useRouter } from 'next/navigation';

interface SearchProps {
    title: string;
    placeholder: string;
    buttonText: string;
    searchUrl: string;
}

const Search: React.FC<SearchProps> = ({
    title,
    placeholder,
    buttonText,
    searchUrl,
}) => {
    const [searchQuery, setSearchQuery] = useState('');
    const router = useRouter();

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        const encodedQuery = encodeURIComponent(searchQuery);
        const fullUrl = `${searchUrl}#query=${encodedQuery}`;
        router.push(fullUrl);
    };

    return (
        <section className="rh-search bg-white py-12 sm:py-24" id="search">
            <div className="container mx-auto px-4 sm:px-6 lg:px-8">
                <div className="">
                    <h2 
                        className="rh-lg mb-8 max-w-2xl"
                        dangerouslySetInnerHTML={{ __html: title }}
                    />
                </div>
                <div className="max-w-5xl">
                    <form onSubmit={handleSubmit} className="relative">
                        <div className="relative">
                            <label 
                                htmlFor="search-input" 
                                className="absolute left-6 sm:left-10 top-5 text-md sm:text-xl font-normal"
                            >
                                search query
                            </label>
                            <input
                                id="search-input"
                                type="text"
                                placeholder={placeholder}
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                className="w-full pt-10 pb-4 sm:pt-14 sm:pb-6 px-6 sm:px-10 pr-48 rounded-full border-2 border-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-200 focus:border-transparent text-xl"
                            />
                            <button
                                type="submit"
                                className="rh-btn-black-lg absolute right-6 top-1/2 transform -translate-y-1/2 bg-black"
                            >
                                {buttonText}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    );
};

export default Search;