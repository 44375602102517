'use client';

import React from 'react';

interface AnimatedContentProps {
    hero: React.ReactNode;
    sections: React.ReactNode[];
}

const AnimatedContent: React.FC<AnimatedContentProps> = ({ hero, sections }) => {
    return (
        <div className="bg-white">
            {hero}
            {sections}
        </div>
    );
};

export default AnimatedContent; 